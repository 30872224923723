import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import { API_URL } from '../config';

const HLSPlayer = ({ videoUrl, markers = [], postHistory, selectedSection }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [snapshots, setSnapshots] = useState({});
  const hlsRef = useRef(null); // Reference to the Hls instance

  const [playedTime, setPlayedTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const playedTimeRef = useRef(0);

  const timeTrackerIntervalRef = useRef(null);

  // useEffect(() => {
  //   const video = videoRef.current;
  //   return () => {
  //     if (video) {
  //       console.log("playedTime", playedTimeRef.current);
  //       postHistory(playedTimeRef.current);
  //     }
  //   }
  // }, []);

  // useEffect(() => { 
  //   if(playedTime) {
  //     console.log("time", playedTime);
  //   }
  // }, [playedTime]); 

  const submitTime = (time) => {
    postHistory(time);
    setPlayedTime(0);
    playedTimeRef.current = 0;
  }

  // useEffect(() => {
  //   if(playedTimeRef.current) {
  //     console.log("playedTimeRef", playedTimeRef.current);
  //   }
  // }, [playedTimeRef.current]);

  // useEffect(() => {
  //     if(videoUrl) {
  //       console.log("videoUrl")
  //       setPlayedTime(0);
  //     }
  // }, [videoUrl]); 

  useEffect(() => {
    
    const video = videoRef?.current;

    const startTrackingTime = () => {
      if (!timeTrackerIntervalRef.current) {
        timeTrackerIntervalRef.current = setInterval(() => {
          setPlayedTime((prevTime) => {
            const newTime = prevTime + 1;
            playedTimeRef.current = newTime; // Update the ref
            return newTime;
          });
        }, 1000);
      }
    };
  
    const stopTrackingTime = () => {
      if (timeTrackerIntervalRef.current) {
        clearInterval(timeTrackerIntervalRef.current);
        timeTrackerIntervalRef.current = null; // Reset the interval ref
      }
    };
  
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        stopTrackingTime();
        submitTime(playedTimeRef.current); // Call submitTime when the tab becomes hidden
      } else if (document.visibilityState === "visible" && isPlaying) {
        startTrackingTime();
      }
    };
  
    const handleBeforeUnload = () => {
      stopTrackingTime();
      submitTime(playedTimeRef.current); // Call submitTime before the page unloads
    };
  
    if (Hls.isSupported() && videoUrl) {
      const hls = new Hls();
      hlsRef.current = hls;
      hls.loadSource(videoUrl);
      hls.attachMedia(video);
  
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        setIsVideoReady(true);
      });
  
      video.addEventListener("playing", () => {
        setIsPlaying(true);
        startTrackingTime();
      });
  
      video.addEventListener("pause", () => {
        setIsPlaying(false);
        stopTrackingTime();
      });
  
      video.addEventListener("ended", () => {
        stopTrackingTime();
        submitTime(playedTimeRef.current); // Call submitTime when the video ends
      });
  
      document.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("beforeunload", handleBeforeUnload);
  
      return () => {
        stopTrackingTime();
        submitTime(playedTimeRef.current); // Call submitTime when the component unmounts or video URL changes
        hls.destroy();
  
        video.removeEventListener("playing", startTrackingTime);
        video.removeEventListener("pause", stopTrackingTime);
        video.removeEventListener("ended", stopTrackingTime);
  
        document.removeEventListener("visibilitychange", handleVisibilityChange);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else if (video && video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = videoUrl;
      video.addEventListener("loadedmetadata", () => {
        setIsVideoReady(true);
      });
  
      video.addEventListener("playing", () => {
        setIsPlaying(true);
        startTrackingTime();
      });
  
      video.addEventListener("pause", () => {
        setIsPlaying(false);
        stopTrackingTime();
      });
  
      video.addEventListener("ended", () => {
        stopTrackingTime();
        submitTime(playedTimeRef.current); // Call submitTime when the video ends
      });
  
      document.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("beforeunload", handleBeforeUnload);
  
      return () => {
        stopTrackingTime();
        submitTime(playedTimeRef.current); // Call submitTime when the component unmounts or video URL changes
  
        video.removeEventListener("playing", startTrackingTime);
        video.removeEventListener("pause", stopTrackingTime);
        video.removeEventListener("ended", stopTrackingTime);
  
        document.removeEventListener("visibilitychange", handleVisibilityChange);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  
    return () => {
      stopTrackingTime();
      submitTime(playedTimeRef.current); // Call submitTime during final cleanup
    };
  }, [videoUrl, markers, postHistory]);
  
  
  

  const captureSnapshot = (marker) => {
    const videoElement = videoRef.current;
    if (!videoElement || isNaN(marker.startTime)) return;

    // Set the video to the specific marker time
    videoElement.currentTime = marker.startTime;

    const onSeeked = () => {
      // Slight delay to ensure the frame is stable before capturing the snapshot
      setTimeout(() => {
        const canvas = canvasRef.current;
        if (canvas && videoElement) {
          const context = canvas.getContext('2d');

          // Set canvas size to video dimensions
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;

          // Draw the current frame of the video on the canvas
          context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

          // Convert canvas content to a base64 image
          const imageUrl = canvas.toDataURL('image/png');
          setSnapshots((prevSnapshots) => ({
            ...prevSnapshots,
            [marker.startTime]: imageUrl,
          }));

          // Remove event listener after snapshot is taken
          videoElement.removeEventListener('seeked', onSeeked);
        }
      }, 200); // Optional small delay for frame stability
    };

    // Add event listener for `seeked` to ensure the snapshot is taken after seeking
    videoElement.addEventListener('seeked', onSeeked, { once: true });
  };

  const handleSelectMarker = (startTime) => {
    if (videoRef.current) {
      videoRef.current.currentTime = startTime;

      videoRef.current.addEventListener(
        'seeked',
        () => {
          videoRef.current.play().catch((e) => {
            console.error('Error trying to play the video:', e);
          });
        },
        { once: true }
      );
    }
  };

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((e) => {
        console.error('Error trying to play the video:', e);
      });
    }
  };

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    
    return `${hours > 0 ? hours + ':' : ''}${
        minutes.toString().padStart(2, '0')
    }:${remainingSeconds.toString().padStart(2, '0')}`;
}


  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <video
        ref={videoRef}
        controls
        width="100%"
        height="100%"
        style={{ width: '100%', height: '100%', background: 'black' }}
      />
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      {!isVideoReady && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          onClick={handlePlay}
        >
          Click to Play
        </div>
      )}
      {
        markers.length > 0 && (
          <div className="marker-list" style={{ background: 'white', display: 'flex', gap: '30px',  cursor: 'pointer', padding: '10px' }}>
        {markers.map((marker, index) => (
          <div key={index} className="marker-item" onClick={() => handleSelectMarker(marker.startTime)}>
            
            {/* {snapshots[marker.startTime] && (
              <img
                src={snapshots[marker.startTime]}
                alt={`Snapshot of ${marker.label}`}
                style={{ width: '100px', height: '60px' }}
              />
            )} */}
              <img
                src={`${API_URL}/api/video/chapter/thumbnail/${marker.image}`}
                alt={' '}
                style={{ width: '100px', height: '60px' }}
              />
              <p style={{color: 'black', fontSize: '12px', margin: 0, marginLeft: '1px' }}>{formatTime(marker.startTime)}</p>
              <div style={{ color: 'black', fontSize: '10px' }}>{marker.title}</div>
          </div>
        ))}
      </div>
        )
      }
    </div>
  );
};

export default HLSPlayer;
