import { useEffect, useState } from "react";
import { PPTTiles } from "./PPTTiles";
import axios from "axios";
import { API_URL } from "../config";
import HLSPlayer from "./VideoPlayer";
import DummyImg from '../assets/images/test.png';
import { postVideoHistory } from "../services/VideoHistory";


export const VideoSection = ({
    ppt,
    selectedSection
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState("");
    const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [videoList, setVideoList] = useState([]);

    const [chapters, setChapters] = useState([]);

    useEffect(() => {
        if(videoList?.length) {
            (async() => {
                const { data } = await axios.get(`${API_URL}/api/video/chapters/${videoList[selectedVideoIndex]._id}`);
                setChapters(data?.data);
            })()
            // setChapters(videoList[0].);
        }
    }, [videoList])

    useEffect(() => {
        if (ppt) {
            (async () => {
                try {
                    if (ppt._id) {
                        console.log("ppt",ppt)
                        const { data } = await axios.get(`${API_URL}/api/video/url/${ppt?._id}`);
                        setVideoList(data?.data);
                        setUrl(`${API_URL}/api/video/stream?key=${data?.data[0]?.url}`);
                    } else {
                        setVideoList([]);
                        setUrl("");
                        console.log("Id not found");
                    }
                } catch (e) {
                    console.log("Error while fetching");
                    setVideoList([]);
                    setUrl("");
                }
            })();
        }
    }, [ppt]);

    const handleSelectVideo = (index) => {
        setSelectedVideoIndex(index)
        const videoUrl = videoList[index].url;
        console.log("videoURl", videoUrl);
        setUrl(`${API_URL}/api/video/stream?key=${videoUrl}`);
    }

    const postHistory = async(time) => {
        await postVideoHistory(ppt.title, time);
    }

    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                overflow: 'scroll',
                background: '#FFFFFF',
                overflowX: 'hidden',

            }}
        >


            <div
                style={{

                    width: "100%",
                    height: "60vh",
                    // position: "relative",
                }}
            >
                <div className="ppt-title">
                    <h3
                        style={{
                            margin: '0px',
                            marginTop: '10px',
                            fontSize: "1.5rem",
                        }}
                    >
                        {ppt.title}
                    </h3>
                    <h5
                        style={{
                            margin: "0px",
                            marginTop: '10px',
                            fontSize: "1rem",
                            fontWeight: "normal",
                            color: 'rgb(127, 140, 141)',
                            lineHeight: '22px',
                            letterSpacing: '1px'
                        }}
                    >
                        {ppt.description}
                    </h5>
                </div>
                {!videoList.length && <div style={{ margin: '10px' }}>Video coming soon...</div>}
                <div style={{ display: "flex", gap: '20px', }} >

                    <div
                        className="iframe-wrapper"
                        style={{
                            marginLeft: '20px',
                            marginTop: '20px',
                            height: "100%",
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                            backgroundColor: "black",
                            position: "relative",
                        }}
                    >
                        {
                            videoList?.length > 0 && (
                                <div style={{ display: "flex" }}>
                                    <HLSPlayer videoUrl={url} markers={chapters} postHistory={postHistory} selectedSection={selectedSection} />

                                </div>

                            )
                        }

                    </div>
                    {videoList.length > 0 && <div style={{
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", marginTop: '20px', borderRadius: '10px', width: '450px', padding: '20px',
                        maxHeight: "60vh",
                        overflowY: "auto"
                    }}>
                        {
                            videoList.map((videoItem, index) =>
                                <div style={{
                                    display: 'flex', gap: '10px', alignItems: "center",
                                    background: index === selectedVideoIndex ? "#e9f2ff" : "#FFFFFF",
                                    border: '1px solid lightgrey',
                                    // border: index === selectedVideoIndex ? '2px solid blue' : '1px solid lightgrey',
                                    borderRadius: '12px', padding: '10px', marginTop: '10px', cursor: 'pointer'
                                }} onClick={() => handleSelectVideo(index)} >
                                    {ppt.thumbnail ? <img src={`${API_URL}/api/ppt/thumbnail/${ppt.thumbnail}`} alt=" " style={{ width: '150px', height: '80px' }} />  : <img src={DummyImg} alt=" " style={{ width: '150px', height: '80px' }} /> }
                                    <p>{videoItem.name}</p>
                                </div>
                            )
                        }
                    </div>}
                </div>
                <div>

                </div>

            </div>

        </div>
    );
};
