import axios from "axios"
import { API_URL, getHeaders } from "../config"

export const getUserModuleReport = async(selectedRange, startDate, endDate) => {
    try {
        const { data } = await axios.get(API_URL + `/api/user-report/overall/${selectedRange}/${startDate}/${endDate}`, { headers: getHeaders().headers });
        return { categories: data?.categories, series: data?.series };
    } catch(e) {
        console.log(e);
        const { status } = e?.response;
        if(!status) return;
        if(status === 401 || status === 403) {
            throw new Error("Unauthorized");
        }
    }
}

export const getUserActivity = async() => {
    try {
        const { data } = await axios.get(API_URL + "/api/user-report/activity", { headers: getHeaders().headers });
        return { count: data?.count, time: data?.time };
    } catch(e) {
        const { status } = e?.response;
        if(!status) return;
        if(status === 401 || status === 403) {
            throw new Error("Unauthorized");
        }
    }
}

export const getUserModuleVideoReport = async(selectedRange, startDate, endDate) => {
    try {
        const { data } = await axios.get(API_URL + `/api/user-report/overall/video/${selectedRange}/${startDate}/${endDate}`, { headers: getHeaders().headers });
        return { categories: data?.categories, series: data?.series };
    } catch(e) {
        console.log(e)
        const { status } = e?.response;
        if(!status) return;
        if(status === 401 || status === 403) {
            throw new Error("Unauthorized");
        }
    }
}