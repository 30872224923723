// export const API_URL = "http://localhost:4000";
// export const API_URL = "https://6f63-182-74-41-20.ngrok-free.app";
export const API_URL="https://docapi.campuscare.cloud";

export const getHeaders = () => {
  const token = localStorage.getItem("token");

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
