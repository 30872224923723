import { Box, Button, Grid2, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getAssessment,
  getWarmup,
  submitAnswers,
} from "../services/AssessmentService";
import { API_URL } from "../config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ReplayIcon from "@mui/icons-material/Replay";
import { toast } from "react-toastify";
import Loader from "./Loader";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { AchievementModal } from "./AchievementModal";
import { useNavigate } from "react-router-dom";

function AssessmentSection({
  selectedModule,
  type = "assessment",
  goBack,
  hideNavigation = "no",
}) {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [value, setValue] = useState("");
  const [change, setChange] = useState(false);
  const [responses, setResponses] = useState({});
  const [assessmentId, setAssessmentid] = useState("");
  const [showAchievements, setShowAchievements] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [markData, setMarkData] = useState({});
  const [startAssessment, setStartAssessment] = useState("start");

  useEffect(() => {
    (async () => {
      setStartAssessment("start");
      setQuestions([]);
      setValue("");
      setMarkData({});
      let response;
      if (type === "warmup") {
        response = await getWarmup(selectedModule._id);
      } else {
        response = await getAssessment(selectedModule._id);
      }
      if (response) {
        setAssessmentid(response._id);
        setQuestions(response.questions);

        

        // Initialize responses object with all questions
        const initialResponses = response?.questions?.reduce(
          (acc, question) => {
            acc[question._id] = {
              type: question.type,
              selectedOptions: question.type === "multipleChoice" ? [] : null,
              selectedOption:
                question.type === "singleChoice" ? null : undefined,
              yesNoAnswer: question.type === "yesNo" ? null : undefined,
              descriptiveAnswer:
                question.type === "descriptive" ? "" : undefined,
              isAnswered: false, // Default flag for unanswered
            };
            return acc;
          },
          {}
        );

        setResponses(initialResponses); // Set initial responses with default structure
      }
    })();
  }, [selectedModule, change]);

  useEffect(() => {
    const currentQuestionId = questions[currentQuestionIndex]?._id;
    if (currentQuestionId) {
      setValue(responses[currentQuestionId]?.descriptiveAnswer || "");
    }
  }, [currentQuestionIndex, responses, questions]);

  const handleMultipleChange = (questionId, option) => {
    setResponses((prev) => {
      const selectedOptions = prev[questionId]?.selectedOptions || [];
      const updatedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((o) => o !== option)
        : [...selectedOptions, option];

      return {
        ...prev,
        [questionId]: {
          ...prev[questionId],
          selectedOptions: updatedOptions.length > 0 ? updatedOptions : null,
        },
      };
    });
  };

  const handleSingleChoiceChange = (questionId, option) => {
    setResponses((prev) => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        selectedOption: option || null,
      },
    }));
  };

  const handleYesNoChange = (questionId, answer) => {
    setResponses((prev) => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        yesNoAnswer: answer || null,
      },
    }));
  };

  const handleDescriptiveChange = (questionId, value) => {
    setResponses((prev) => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        descriptiveAnswer:
          value.trim() && value !== "<p><br></p>" ? value : null,
      },
    }));
    setValue(value); // Update local Quill value
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const currentQuestion = questions[currentQuestionIndex];

  const handleSubmit = async () => {
    setStartAssessment("loading");

    const answers = Object.entries(responses).map(([questionId, value]) => ({
      questionId,
      ...value,
    }));

    const result = await submitAnswers({
      assessmentId: assessmentId,
      answers,
    });
    if (result.success) {
      setMarkData(result.data.markData);
      setStartAssessment("finished");
    } else {
      toast.error("There was an error submitting the assessment.");
    }
  };

  function handleContinue() {
    setChange(!change);
    setCurrentQuestionIndex(0);
    setValue("");
    setMarkData({});
    setStartAssessment("start");
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        overflowY: "auto",
        background: "#FFFFFF",
        overflowX: "hidden",
        pb: 10,
      }}
    >
      {showAchievements && (
        <AchievementModal
          open={showAchievements}
          module={selectedModule.title}
          show={setShowAchievements}
        />
      )}
      <div
        className="ppt-title"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {type === "warmup" ? (
          <Box display={"flex"} alignItems={"flex-end"}>
            {hideNavigation !== "yes" && (
              <IconButton
                onClick={() => goBack("list")}
                size="small"
                style={{ color: "black" }}
              >
                <ChevronLeftIcon />
              </IconButton>
            )}
            <Typography
              style={{
                margin: "0px",
                marginTop: "10px",
                fontSize: "1.5rem",
                fontWeight: 700,
              }}
            >
              {selectedModule.name}
            </Typography>
          </Box>
        ) : (
          <h3 style={{ margin: "0px", marginTop: "10px", fontSize: "1.5rem" }}>
            warmup
          </h3>
        )}

        <Button
          onClick={() => setShowAchievements(true)}
          endIcon={<EmojiEventsIcon />}
          variant="contained"
          sx={{
            mr: 2,
            textTransform: "none",
            background: "#2e64b8",
            borderRadius: 3,
          }}
        >
          Achievements
        </Button>
      </div>
      <Box>
        {startAssessment === "start" && (
          <Box class="assessment-container">
            {type !== "warmup" && (
              <div class="typewriter">{selectedModule.title}</div>
            )}
            {questions?.length > 0 ? (
              <Typography my={3} fontSize={"1.5rem"}>
                {type === "warmup"
                  ? "Start your learning adventure with this quiz!"
                  : "A quick way to get ready for this module."}
              </Typography>
            ) : (
              <Typography my={3} fontSize={"1.5rem"}>
                {type === "warmup"
                  ? "This quiz is coming soon.........."
                  : "Assessment for this module coming soon ....."}
              </Typography>
            )}
            {questions.length > 0 && (
              <Button
                endIcon={<ArrowCircleRightIcon />}
                onClick={() => setStartAssessment("middle")}
                variant="contained"
                sx={{ textTransform: "none", borderRadius: 5, p: 1.5 }}
              >
                Practice Now
              </Button>
            )}
          </Box>
        )}

        {startAssessment === "middle" && (
          <>
            <Grid2
              container
              py={1}
              px={2}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid2
                display={"flex"}
                alignItems={"center"}
                maxWidth={"600px"}
                overflow={"auto"}
                // justifyContent={"center"}
                pb={3}
                size={12}
              >
                {questions?.length &&
                  questions.map((data, index) => (
                    <Box display={"flex"} alignItems={"center"}>
                      <Box
                        onClick={() => setCurrentQuestionIndex(index)}
                        display={"flex"}
                        bgcolor={
                          responses[data._id] &&
                          ((responses[data._id].type === "multipleChoice" &&
                            responses[data._id].selectedOptions &&
                            responses[data._id].selectedOptions.length > 0) ||
                            (responses[data._id].type === "singleChoice" &&
                              responses[data._id].selectedOption) ||
                            (responses[data._id].type === "yesNo" &&
                              responses[data._id].yesNoAnswer) ||
                            (responses[data._id].type === "descriptive" &&
                              responses[data._id].descriptiveAnswer &&
                              responses[data._id].descriptiveAnswer.trim() !==
                                "<p><br></p>"))
                            ? "green" // Answered questions in green
                            : currentQuestionIndex === index
                            ? "#2e64b8" // Current question in blue
                            : "" // Default background color
                        }
                        border={"1px solid #2e64b8"}
                        sx={{ cursor: "pointer" }}
                        height={"45px"}
                        width={"45px"}
                        borderRadius={"50%"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography
                          color={
                            currentQuestionIndex === index
                              ? "white"
                              : (responses[data._id]?.type ===
                                  "multipleChoice" &&
                                  responses[data._id].selectedOptions &&
                                  responses[data._id].selectedOptions.length >
                                    0) ||
                                (responses[data._id]?.type === "singleChoice" &&
                                  responses[data._id].selectedOption) ||
                                (responses[data._id]?.type === "yesNo" &&
                                  responses[data._id].yesNoAnswer) ||
                                (responses[data._id]?.type === "descriptive" &&
                                  responses[data._id].descriptiveAnswer &&
                                  responses[
                                    data._id
                                  ].descriptiveAnswer.trim() !== "<p><br></p>")
                              ? "white" // Answered questions in white text
                              : "#2e64b8" // Default color for unanswered questions
                          }
                        >
                          {index + 1}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          height: "2px",
                          width: "40px",
                          bgcolor: "#2e64b8",
                          display: questions.length === index + 1 ? "none" : "",
                        }}
                      />
                    </Box>
                  ))}
              </Grid2>
            </Grid2>

            <Grid2
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              pb={10}
            >
              <Grid2 size={{ md: 9, lg: 8, xl: 6, xs: 12 }}>
                {questions.length > 0 && currentQuestion ? (
                  <Box
                    border={"1px solid #dadce0"}
                    my={2}
                    sx={{
                      background: "#ffff",
                      borderRadius: 4,
                    }}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"end"}
                      px={{ xs: 2 }}
                      pt={2}
                    >
                      <Typography
                        bgcolor={"#dadce0"}
                        fontSize={"14px"}
                        fontWeight={500}
                        height={"35px"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        px={1}
                        borderRadius={4}
                        style={{ marginLeft: "10px" }}
                      >
                        Score : {currentQuestion.mark}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        borderBottom: "1px solid #dadce0",
                        px: { md: 4, xs: 2 },
                        pb: 3,
                        pt: 1,
                      }}
                    >
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px",
                          color: "black",
                          fontWeight: "bold",
                          borderRadius: "50%",
                          height: "30px",
                          padding: "10px",
                          width: "30px",
                          border: "1px solid #dadce0",
                        }}
                      >
                        {currentQuestionIndex + 1}/{questions.length}
                      </Typography>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: currentQuestion.text,
                        }}
                      />
                    </Box>

                    {currentQuestion.file && (
                      <Box
                        sx={{
                          p: { md: 4, xs: 2 },
                          borderBottom: "1px solid #dadce0",
                        }}
                        my={1}
                      >
                        <img
                          style={{ objectFit: "contain" }}
                          height={"100%"}
                          width={"100%"}
                          src={`${API_URL}/api/assessment/${currentQuestion.file}`}
                        />
                      </Box>
                    )}

                    {/* Render options or answer fields based on question type */}
                    <Box px={{ md: 4, xs: 2 }} py={1}>
                      {currentQuestion.type === "multipleChoice" &&
                        currentQuestion.options && (
                          <>
                            <Typography my={2} fontSize={"1rem"}>
                              Choose one or more options:
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                              }}
                            >
                              {currentQuestion.options.map(
                                (option, optIndex) => (
                                  <Box
                                    key={optIndex}
                                    borderRadius={3}
                                    pl={2}
                                    py={2}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    gap={1}
                                    width={"100%"}
                                    bgcolor={"#f8f9fa"}
                                    sx={{
                                      "&:hover": {
                                        boxShadow:
                                          "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                      },
                                    }}
                                  >
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={
                                          responses[
                                            currentQuestion._id
                                          ]?.selectedOptions?.includes(
                                            option.text
                                          ) || false
                                        }
                                        onChange={() =>
                                          handleMultipleChange(
                                            currentQuestion._id,
                                            option.text
                                          )
                                        }
                                      />
                                      {option.text}
                                    </label>
                                    {option.file && (
                                      <img
                                        style={{
                                          height: "150px",
                                          width: "150px",
                                        }}
                                        src={`${API_URL}/api/assessment/${option.file}`}
                                      />
                                    )}
                                  </Box>
                                )
                              )}
                            </Box>
                          </>
                        )}
                      {currentQuestion.type === "singleChoice" &&
                        currentQuestion.options && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Typography>Choose one option:</Typography>
                            {currentQuestion.options.map((option, optIndex) => (
                              <Box
                                key={optIndex}
                                borderRadius={3}
                                pl={2}
                                py={2}
                                display={"flex"}
                                flexDirection={"column"}
                                gap={1}
                                width={"100%"}
                                bgcolor={"#f8f9fa"}
                                sx={{
                                  "&:hover": {
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                  },
                                }}
                              >
                                <label>
                                  <input
                                    type="radio"
                                    name={`singleChoice-${currentQuestionIndex}`}
                                    checked={
                                      responses[currentQuestion._id]
                                        ?.selectedOption === option.text
                                    }
                                    onChange={() =>
                                      handleSingleChoiceChange(
                                        currentQuestion._id,
                                        option.text
                                      )
                                    }
                                  />
                                  {option.text}
                                </label>
                                {option.file && (
                                  <img
                                    style={{ height: "150px", width: "150px" }}
                                    src={`${API_URL}/api/assessment/${option.file}`}
                                  />
                                )}
                              </Box>
                            ))}
                          </Box>
                        )}
                      {currentQuestion.type === "yesNo" && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <label>Yes/No:</label>
                          <Box
                            borderRadius={3}
                            pl={2}
                            py={2}
                            width={"100%"}
                            bgcolor={"#f8f9fa"}
                            sx={{
                              "&:hover": {
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                              },
                            }}
                          >
                            <label>
                              <input
                                type="radio"
                                name={`yesNo-${currentQuestionIndex}`}
                                checked={
                                  responses[currentQuestion._id]
                                    ?.yesNoAnswer === "yes"
                                }
                                onChange={() =>
                                  handleYesNoChange(currentQuestion._id, "yes")
                                }
                              />
                              Yes
                            </label>
                          </Box>
                          <Box
                            borderRadius={3}
                            pl={2}
                            py={2}
                            width={"100%"}
                            bgcolor={"#f8f9fa"}
                            sx={{
                              "&:hover": {
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                              },
                            }}
                          >
                            <label>
                              <input
                                type="radio"
                                name={`yesNo-${currentQuestionIndex}`}
                                checked={
                                  responses[currentQuestion._id]
                                    ?.yesNoAnswer === "no"
                                }
                                onChange={() =>
                                  handleYesNoChange(currentQuestion._id, "no")
                                }
                              />
                              No
                            </label>
                          </Box>
                        </Box>
                      )}
                      {currentQuestion.type === "descriptive" && (
                        <>
                          <Typography my={1}>Your Answer:</Typography>
                          <Box
                            borderRadius={3}
                            pl={2}
                            py={2}
                            width={"100%"}
                            bgcolor={"#f8f9fa"}
                            sx={{
                              "&:hover": {
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                              },
                            }}
                          >
                            <Box pr={2}>
                              <ReactQuill
                                theme="snow"
                                value={value}
                                onChange={(value) =>
                                  handleDescriptiveChange(
                                    currentQuestion._id,
                                    value
                                  )
                                }
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>

                    {/* Next and Previous Buttons */}
                    <Box
                      sx={{ p: { md: 2, xs: 2 } }}
                      display={"flex"}
                      justifyContent={"end"}
                      gap={1}
                      mt={2}
                    >
                      <IconButton
                        onClick={handlePrevious}
                        sx={{
                          border: "1px solid #1b6ef3",
                          color: "#1b6ef3",
                          display: currentQuestionIndex === 0 ? "none" : "flex",
                        }}
                      >
                        <ChevronLeftIcon />
                      </IconButton>
                      {currentQuestionIndex === questions.length - 1 ? (
                        <Button
                          sx={{
                            background: "#1b6ef3",
                            color: "white",
                            borderRadius: 4,
                            px: 2,
                          }}
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      ) : (
                        <IconButton
                          onClick={handleNext}
                          sx={{ border: "1px solid  ", color: "#1b6ef3" }}
                          disabled={
                            currentQuestionIndex === questions.length - 1
                          }
                        >
                          <ChevronRightIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box border={"1px solid #dadce0"} borderRadius={4}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "60vh",
                        color: "grey",
                      }}
                    >
                      <h5>No Questions to show</h5>
                    </div>
                  </Box>
                )}
              </Grid2>
            </Grid2>
          </>
        )}
        {startAssessment === "loading" && <Loader />}

        {startAssessment === "finished" && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            justifyContent={"center"}
            minHeight={"80vh"}
            width={"100%"}
            alignItems={"center"}
          >
            <Box
              borderRadius={5}
              py={5}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              gap={3}
              sx={{
                background:
                  "linear-gradient(180deg, #75f 0%, #6943ff 0.01%, #2f2ce9 100%)",
              }}
              mt={4}
              minWidth={"25vw"}
              height={"40vh"}
            >
              <Typography fontSize={"1.5rem"} color="#cac9ff" fontWeight={700}>
                Your Result
              </Typography>
              <Box
                height={"150px"}
                sx={{
                  background:
                    "linear-gradient(180deg, #4d21c9 0%, rgba(37, 33, 201, 0) 100%, rgba(37, 33, 201, 0) 100%)",
                }}
                color={"white"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"150px"}
                borderRadius={"50%"}
              >
                <Typography fontWeight={700} fontSize={"2.6rem"}>
                  {markData?.totalMarks}
                </Typography>
                <Typography>of {markData?.totalScore}</Typography>
              </Box>
              {(() => {
                const percentage =
                  (markData.totalMarks / markData.totalScore) * 100;
                let feedback;

                if (percentage < 50) {
                  feedback = "Needs Improvement";
                } else if (percentage < 70) {
                  feedback = "Good";
                } else if (percentage < 90) {
                  feedback = "Very Good";
                } else {
                  feedback = "Excellent";
                }

                return (
                  <Typography
                    fontSize={"1.5rem"}
                    fontWeight={600}
                    color={"#cac9ff"}
                  >
                    {feedback}
                  </Typography>
                );
              })()}
            </Box>
            <Button
              onClick={handleContinue}
              startIcon={<ReplayIcon />}
              sx={{
                borderRadius: 3,
                p: 2,
                textTransform: "none",
                fontSize: "1.2rem",
              }}
              variant="outlined"
            >
              Practice again
            </Button>
            <Box
              mt={4}
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              gap={5}
              mb={12}
            >
              {markData?.feedBack
                ?.sort((a, b) => a.questionNumber - b.questionNumber) // Sort by questionNumber
                .map((data, index) => {
                  const answerData = markData.answers.find(
                    (answer) => answer.questionId === data.id
                  );
                  const questionData = questions.find(
                    (question) => question._id === data.id
                  ).text;

                  return (
                    <Box
                      key={index}
                      borderRadius={5}
                      width={"60%"}
                      sx={{
                        overflow: "hidden",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <Box
                        borderBottom={"1px solid lightgrey"}
                        sx={{
                          background:
                            data.submittedAnswer?.type === "descriptive"
                              ? "#edf0f5"
                              : answerData?.isCorrect
                              ? "#d4edda"
                              : "#f8d7da",
                        }}
                      >
                        <Box
                          color={"#88909d"}
                          fontWeight={600}
                          px={2}
                          pt={2}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            p={1}
                            gap={1}
                            sx={{
                              background:
                                data.submittedAnswer?.type === "descriptive"
                                  ? "grey"
                                  : answerData?.isCorrect
                                  ? "#14bb14"
                                  : "#ff6060",
                              color: "white",
                              borderRadius: 3,
                            }}
                          >
                            <Typography>Mark</Typography>
                            <Typography>
                              {data.mark}/{data.outOfMark}
                            </Typography>
                          </Box>

                          <Typography>
                            {data.questionNumber}/{questions.length}
                          </Typography>
                        </Box>
                        <Box py={1} px={4}>
                          <Typography
                            fontSize={"1.3rem"}
                            // color={"#88909d"}
                            color={"black"}
                            dangerouslySetInnerHTML={{
                              __html: questionData || "Question text",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box pb={1} mt={2} px={4}>
                        <Typography>Your Answer</Typography>
                        <Typography
                          fontSize={"1rem"}
                          color={"#88909d"}
                          dangerouslySetInnerHTML={{
                            __html:
                              data.submittedAnswer?.type === "descriptive"
                                ? data.submittedAnswer?.descriptiveAnswer
                                : data.submittedAnswer?.type ===
                                  "multipleChoice"
                                ? data.submittedAnswer?.selectedOptions.join(
                                    ", "
                                  )
                                : data.submittedAnswer?.selectedOption,
                          }}
                        ></Typography>
                      </Box>
                      <Box pb={2} px={4}>
                        <Typography>
                          {data.submittedAnswer?.type === "descriptive"
                            ? "Feedback"
                            : "Correct Answer"}
                        </Typography>
                        {data.submittedAnswer?.type === "descriptive" ? (
                          data.correctAnswer.length > 0 ? (
                            data.correctAnswer.map((point, index) => (
                              <Typography
                                key={index}
                                my={1}
                                fontSize={"1rem"}
                                color={"#88909d"}
                              >
                                {index + 1}.{point}
                              </Typography>
                            ))
                          ) : (
                            <Typography
                              key={index}
                              my={1}
                              fontSize={"1rem"}
                              color={"#88909d"}
                            >
                              ''
                            </Typography>
                          )
                        ) : (
                          <Typography fontSize={"1rem"} color={"#88909d"}>
                            {Array.isArray(data.correctAnswer)
                              ? data.correctAnswer.join(", ")
                              : data.correctAnswer}
                          </Typography>
                        )}
                      </Box>
                      {data.submittedAnswer?.type === "yesNo" &&
                        data.feedback !== "" && (
                          <Box pb={1} mt={2} px={4}>
                            <Typography>Feedback</Typography>
                            <Typography
                              fontSize={"1rem"}
                              color={"#88909d"}
                              dangerouslySetInnerHTML={{
                                __html: data.feedback,
                              }}
                            ></Typography>
                          </Box>
                        )}
                    </Box>
                  );
                })}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default AssessmentSection;
