import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";

import { keyframes } from "@emotion/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getAnswers } from "../services/AssessmentService";

const BootstrapDialog = styled(Dialog)(({ theme, mode }) => ({
  "& .MuiPaper-root": {
    background: "white", // Set the background based on the mode
    position: "absolute",
    top: 50,
    right: 0,
  },
  "& .MuiDialog-paper": {
    minWidth: "300px",
    maxWidth: "400px",
    maxHeight: "600px",
    borderRadius: "8px !important",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const AchievementModal = ({ open, show, module }) => {
  const [switchTab, setSwitchTab] = useState("Me");
  const [myScore, setMyScore] = useState([]);
  const [teamScore, setTeamScore] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAnswers();

        if (response.data) {
          const teamScoreData = response.data.totalScore;
          const myScoreData = response.data.myScore;

          // Find the index of the item where `data._id?.name` matches the `module`
          const moduleIndex = teamScoreData.findIndex(
            (data) => data._id?.name === module
          );
          const moduleMyIndex = myScoreData.findIndex(
            (data) => data.assessment?.name === module
          );

          if (moduleIndex > -1) {
            // Remove the found item and place it at the beginning of the array
            const [moduleItem] = teamScoreData.splice(moduleIndex, 1);
            teamScoreData.unshift(moduleItem);
          }
          if (moduleMyIndex > -1) {
            // Remove the found item and place it at the beginning of the array
            const [moduleItem] = myScoreData.splice(moduleMyIndex, 1);
            myScoreData.unshift(moduleItem);
          }

          setMyScore(myScoreData);
          setTeamScore(teamScoreData);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  function handleClose() {
    show(false);
  }

  const slideDown = keyframes`
    0% {
      top: 150px;
      transform: rotate(360deg) translateX(300px);
    }
    100% {
      top: 260px;
      transform: rotate(0deg) translateX(0px);
    }
  `;

  return (
    <>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          width={"100%"}
          height={"250px"}
          position="relative"
          overflow="hidden"
        >
          <div
            style={{
              marginTop: "10px",
              position: "absolute",
              zIndex: 500,
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography fontWeight={500} color={"white"} mt={1} mb={2}>
              Achievements
            </Typography>
            <Box
              sx={{
                background: "#4e42a9",
                borderRadius: "8px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                width: "65%",
                px: "4px",
              }}
            >
              <Button
                onClick={() => setSwitchTab("Me")}
                disableTouchRipple
                sx={{
                  textTransform: "none",
                  width: "50%",
                  height: "25px",
                  background: switchTab === "Me" ? "#9087e5" : "#4e42a9",
                  color: switchTab === "Me" ? "white" : "#6d63b5",
                  fontSize: "13px",
                }}
              >
                Me
              </Button>
              <Button
                onClick={() => setSwitchTab("Team")}
                disableRipple
                sx={{
                  textTransform: "none",
                  height: "25px",
                  width: "50%",
                  background: switchTab === "Team" ? "#9087e5" : "#4e42a9",
                  color: switchTab === "Team" ? "white" : "#6d63b5",
                  fontSize: "13px",
                }}
              >
                Overall
              </Button>
            </Box>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              size="small"
              sx={{
                position: "absolute",
                right: 10,
                top: 0,
                color: "white",
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <Box
            width={"300px"}
            height={"350px"}
            position="absolute"
            top={"-150px"} // Adjust the top position to move the semicircle up
            left={"calc(50% - 150px)"} // Center the semicircle horizontally
            borderRadius={"40%"}
            sx={{
              background: "#6a5ae0",
              borderBottom: `2px solid ${"#d3af37"}`,
            }}
          >
            <Box
              sx={{
                border: `2px solid ${"#d3af37"}`,
                background: "white",
                borderRadius: "50%",
                height: "125px",
                width: "125px",
                position: "absolute",
                bottom: "-25px", // Position half of the circle outside the semicircle
                left: "calc(50% - 60px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                animation: `${slideDown} 1s ease-in-out forwards`, // Center the circle horizontally
              }}
            >
              <img
                style={{ width: "80px", height: "80px" }}
                src="https://img.freepik.com/free-vector/realistic-illustration-gold-cup-with-red-ribbon-winner-leader-champion_1262-13474.jpg?ga=GA1.1.928615363.1721802194&semt=ais_hybrid"
              />
            </Box>
          </Box>
        </Box>
        {switchTab === "Me" ? (
          <Box sx={{ maxHeight: "200px", overflowY: "auto", mb: 3 }}>
            <div>
              <Box
                sx={{
                  px: 3,
                  pt: 1,
                  pb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "15px",
                }}
              >
                <Box display={"flex"} alignItems={"center"} gap={2}>
                  <Typography
                    fontWeight={600}
                    fontSize={"15px"}
                    color={"#6a5ae0"}
                  >
                    Module
                  </Typography>
                </Box>
                <Typography
                  fontSize={"15px"}
                  sx={{
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                    alignItems: "center",
                    fontWeight: 600,
                  }}
                  color={"#6a5ae0"}
                >
                  Highest
                </Typography>
              </Box>
              <Divider />
            </div>
            {myScore.length > 0 ? (
              myScore.map((data, index) => (
                <div key={index}>
                  <Box
                    sx={{
                      px: 3,
                      pt: 1,
                      pb: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "15px",
                    }}
                  >
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                      <Typography
                        fontWeight={500}
                        fontSize={"15px"}
                        color={"black"}
                      >
                        {data.assessment.name}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        borderRadius: "50%",
                        height: "25px",
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                        alignItems: "center",
                        fontWeight: 500,
                      }}
                      color={"#d3af37"}
                      variant="caption"
                    >
                      {data.percentage.toFixed(2)}%
                    </Typography>
                  </Box>
                  <Divider />
                </div>
              ))
            ) : (
              <Typography mt={2} textAlign={"center"}>
                No data
              </Typography>
            )}
          </Box>
        ) : (
          <Box
            sx={{ maxHeight: "200px", overflowY: "auto", pb: 1, pt: 1, mb: 3 }}
          >
            <Divider />
            {teamScore.length > 0 ? (
              teamScore.map((data, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Box
                    px={3}
                    mt={2}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <Typography
                      fontWeight={600}
                      fontSize={"15px"}
                      color={"#6a5ae0"}
                      mb={1}
                    >
                      {data._id?.name || "Unknown Module"}
                    </Typography>
                  </Box>

                  {data?.topScores.map((score, scoreIndex) => (
                    <Box
                      key={score._id}
                      sx={{
                        px: 3,

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "15px",
                        // mt: 1,
                      }}
                    >
                      <Box display={"flex"} alignItems={"center"} gap={2}>
                        {scoreIndex + 1 === 1 ? (
                          <img
                            src="https://img.freepik.com/free-vector/golden-medal-design_1166-34.jpg?ga=GA1.1.928615363.1721802194&semt=ais_hybrid"
                            alt="1"
                            style={{
                              height: "50px",
                              width: "50px",
                              objectFit: "fill",
                            }}
                          />
                        ) : scoreIndex + 1 === 2 ? (
                          <img
                            src="https://img.freepik.com/free-vector/silvery-medal-design_1166-23.jpg?ga=GA1.1.928615363.1721802194&semt=ais_hybrid"
                            alt="2"
                            style={{
                              height: "50px",
                              width: "50px",
                              objectFit: "fill",
                            }}
                          />
                        ) : scoreIndex + 1 === 3 ? (
                          <img
                            src="https://img.freepik.com/free-vector/bronzed-medal-design_1166-32.jpg?ga=GA1.1.928615363.1721802194&semt=ais_hybrid"
                            alt="3"
                            style={{
                              height: "50px",
                              width: "50px",
                              objectFit: "fill",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <Typography
                          fontWeight={500}
                          fontSize={"15px"}
                          color={"black"}
                        >
                          {score.user?.userName || "Unknown User"}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          borderRadius: "50%",
                          height: "25px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: 500,
                        }}
                        color={"#d3af37"}
                        variant="caption"
                      >
                        {score.percentage.toFixed(2)}%
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ))
            ) : (
              <Typography mt={2} textAlign={"center"}>
                No data
              </Typography>
            )}
          </Box>
        )}
      </BootstrapDialog>
    </>
  );
};
