import { useNavigate } from "react-router-dom"
import VideoPlayer from "../components/VideoPlayer";
import { API_URL } from "../config";
import HLSPlayer from "../components/VideoPlayer";

export const Test = () => {
    const navigate = useNavigate();

    const markers = [
      {
        startTime: 3, 
        endTime: 15,
        label: 'First Marker', 
        top: 20, 
        left: 30,
        snapshot: ""
      },
      {
        startTime: 10,
        endTime: 35,
        label: 'Second Marker',
        top: 50,
        left: 50,
        snapshot: ""

      },
      {
        startTime: 18,
        endTime: 50,
        label: 'Third Marker',
        top: 10,
        left: 80,
        snapshot: ""

      },
    ];
    
 return (
    <>

    <h4 > Video Player</h4>
    <HLSPlayer videoUrl={`${API_URL}/api/video/stream?key=279f47a5-9df4-49f6-8326-41ce3910cadb/output.m3u8`} markers={markers} />
    </>

 )   
}