import { useEffect, useState } from "react";
import FullScreenIcon from "../assets/images/fullscreen.png";
import axios from "axios";
import { API_URL, getHeaders } from "../config";
import { useNavigate } from "react-router-dom";

export const FullScreenPPTViewer = ({
  ppt,
  handleFullScreen,
  isFullScreen,
  selectedCategoryIndex,
  selectedPPTIndex,
  setCategories
}) => {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (ppt.ppt) {
      console.log("ppt---", ppt.ppt);
      const fetchSignedUrl = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${API_URL}/api/PPT/signed-url?key=${ppt.ppt}`,
            { headers: getHeaders().headers }
          );
          const signedUrl = encodeURIComponent(response.data?.url);
          console.log("Signed URL fetched:", signedUrl);
          setUrl(signedUrl); // Set the signed URL
        } catch (e) {
          console.error("Error fetching signed URL", e);
          setIsLoading(false);
          const { status } = e?.response;
        if (!status) return;
        if (status === 401 || status === 403) {
          handleLogout();
        }
        }
      };

      fetchSignedUrl();
    }
  }, [ppt._id]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleIframeLoad = async () => {
    console.log("Iframe has loaded content.");
    try {
      // Now that the iframe has loaded the signed URL, perform the backend update
      const response = await axios.get(
        `${API_URL}/api/PPT/update?key=${ppt.ppt}`
      );
      updateCategoryItem(
        selectedCategoryIndex,
        selectedPPTIndex,
        response?.data?.key
      ); // Update categories with new key
      setIsLoading(false);
    } catch (error) {
      console.error("Error updating key after iframe load", error);
      setIsLoading(false);
    }
  };

  const updateCategoryItem = (categoryIndex, itemIndex, pptUrl) => {
    console.log("ppt-------------", pptUrl);
    setCategories((prevCategories) => {
      // Deep clone the previous categories array
      const updatedCategories = JSON.parse(JSON.stringify(prevCategories));

      updatedCategories[categoryIndex].items[itemIndex].ppt = pptUrl;

      return updatedCategories;
    });
  };

  const handleReload = () => {
    window.location.reload();
  }

  return (
    <div
      className="iframe-wrapper"
      style={{
        width: "100vw",
        height: "100vh",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)", // Box-shadow for the wrapper
        borderRadius: "10px", // Border radius for the wrapper
        overflow: "hidden", // Ensures the iframe respects the border-radius
        backgroundColor: "white",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          height: "100px",
          width: "100vw",
          background: "transparent",
        }}
      >
        <img
          src={FullScreenIcon}
          style={{
            cursor: "pointer",
            width: "50px",
            height: "50px",
            marginRight: "20px",
            position: "absolute",
            right: 0,
            top: 20,
            zIndex: 11,
          }}
          onClick={handleFullScreen}
          alt=" "
        />
      </div>

      {isLoading && <p>Loading PPT...</p>}
        <iframe
          id="ppt-element"
          title="PPT Viewer"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}&embedded=true&wdHideDownload=1`}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            pointerEvents: isFullScreen ? "all" : "none",
            // pointerEvents: "auto"
          }}
          frameBorder="0"
          onEncrypted={true}
          onLoad={handleIframeLoad}
          onError={handleReload}
        />
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "42vw",
          height: "24px",
          background: "#FFFFFF",
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "42vw",
          height: "24px",
          background: "#FFFFFF",
        }}
      ></div>
    </div>
  );
};
