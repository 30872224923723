import { useEffect, useState } from "react";
import DC from "../assets/images/DC.png";
import ERP from "../assets/images/ERP.jpg";
import FAQ from "../assets/images/FAQ.png";
import Sales from "../assets/images/Sales.jpg";
import Support from "../assets/images/support.png";
import { UserModuleAnalysis } from "../components/UserModuleAnalysis";
import { getUserActivity } from "../services/UserReportService";
import { UserModuleVideoAnalysis } from "../components/UserModuleVideoAnalysis";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

export const Categories = () => {

  const userName = localStorage.getItem("userName");

  const navigate = useNavigate();

  const sections = [
    {
      name: "ERP",
      image: ERP,
      permission: ['Sales']
    },
    {
      name: "LMS DC",
      image: DC,
      permission: ['Sales']
    },
    {
      name: "FAQ",
      image: FAQ,
      permission: ['Sales']
    },
    {
      name: "Sales",
      image: Sales,
      permission: ['Sales']
    },
    {
      name: "Support",
      image: Support,
      permission: ['Support']
    }
  ];

  const [loginCount, setLoginCount] = useState(0);
  const [activeTime, setActiveTime] = useState(0);
  
  const role = localStorage.getItem('role');

  useEffect(() => {
    (async() => {
      try {
        const { count, time } = await getUserActivity();
        setLoginCount(count);
        setActiveTime(time);
      } catch(e) {
        if(e) {
          console.log("Error:", e);
          if(e.message === "Unauthorized") {
            handleLogout();
          }
        }
      }
    })();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleSelectSection = (section) => {
    localStorage.setItem('section', section);
    navigate('/modules')
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '20px' }}>
        <h2 style={{ margin: "30px 0px 20px 50px" }}>Hello {userName}</h2>
        <button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100px",
          marginTop: "auto",
          alignSelf: "center",
          marginBottom: "10px",
          backgroundColor: "white",
          border: "1px solid lightblue",
          borderRadius: "10px",
          color: "rgb(78, 115, 223)",
          padding: "10px",
          fontSize: "15px",
          cursor: "pointer",
        }}
        onClick={handleLogout}
      >
        <LogoutIcon style={{ color: "rgb(78, 115, 223)" }} />
        Logout
      </button>
      </div>
      <div style={{ display: "flex", justifyContent: "start", alignItems: 'start', flexWrap: "nowrap" }}>
  <div
    style={{
      marginTop: "10px",
      marginLeft: '50px',
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      gap: "50px",
      width: '70vw',
      flexShrink: 1, // Allow items to shrink to fit on smaller screens
    }}
  >
    {sections.map((section) => (
      <div
        className="report-item"
        style={{
          display: section.permission.includes(role) ? "flex" : 'none',
          alignItems: "center",
          flexDirection: "column",
          width: "200px", // Default width for each item
          height: "150px",
          borderRadius: "20px",
          marginBottom: '20px', // Ensure there’s space between items
          flexShrink: 1, // Allow item to shrink when needed
        }}
        onClick={() => handleSelectSection(section.name)}
      >
        <img
          src={section.image}
          alt=""
          style={{ width: "60%", height: "60%" }}
        />
        <h3 style={{ fontWeight: "normal" }}>{section.name}</h3>
      </div>
    ))}
  </div>

  <div 
    style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      marginLeft: '50px', 
      gap: '20px',
      width: "20vw",
      flexShrink: 1, // Allow the sidebar to shrink as well
      
    }}
  >
    <div
      className="report-item"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        flexDirection: "column",
        width: "100%",
        height: "80px",
        borderRadius: "20px",
        background: "linear-gradient(135deg, #4e73df, #1f2a7a)",
        padding: '10px',
        flexShrink: 1, // Allow the item to shrink
      }}
    >
      <h4 style={{ color: "white" }}>Total Active Time : <span style={{ color: 'white', marginLeft: '20px', fontSize: "22px" }}>{activeTime}</span></h4>
    </div>
    <div
      className="report-item"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        flexDirection: "column",
        width: "100%",
        height: "80px",
        borderRadius: "20px",
        background: "linear-gradient(180deg, #87CEEB, #4682B4)",
        padding: '10px',
        flexShrink: 1, // Allow the item to shrink
      }}
    >
      <h4 style={{ color: "white" }}>Total Login Count : <span style={{ color: 'white', marginLeft: '20px', fontSize: "22px" }}>{loginCount}</span></h4>
    </div>
  </div>
</div>

      <div style={{ display: "flex", marginTop: '20px' }}>
        <UserModuleAnalysis />
        <UserModuleVideoAnalysis />
      </div>
    </>
  );
};
