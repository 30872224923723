import { useEffect, useState } from "react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { getAllWarmUp } from "../services/AssessmentService";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AssessmentSection from "../components/AssessmentSection";
import { Box, Button, Grid2, Typography } from "@mui/material";

export const Warm = () => {
  const [warmupData, setWarmupData] = useState([]);
  const [showAssessment, setShowAssessment] = useState("list");
  const [selectedWarmup, setSelectedWarmup] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllWarmUp();
        if (response.data) {
          setWarmupData(response.data);
        }
        console.log(warmupData);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleWarmpClick = (data) => {
    setSelectedWarmup({
      ...data,
      title: data.name || "Default Title", // Assign data.title or a default value
    });
    setShowAssessment("single");
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          overflowY: "auto",
          background: "#FFFFFF",
          overflowX: "hidden",
          pb: 10,
        }}
      >
        {showAssessment === "single" && selectedWarmup !== "" && (
          <AssessmentSection
            type="warmup"
            selectedModule={selectedWarmup}
            goBack={setShowAssessment}
          />
        )}
        {showAssessment === "list" && (
          <>
            <div
              className="ppt-title"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box display={"flex"} mt={1} gap={1} alignItems={"center"}>
                <Typography
                  style={{
                    margin: "0px",
                    fontSize: "1.5rem",
                    fontWeight: 700,
                  }}
                >
                  Warm Up
                </Typography>
              </Box>
              {/* <Button
                //   onClick={() => setShowAchievements(true)}
                endIcon={<EmojiEventsIcon />}
                variant="contained"
                sx={{
                  mr: 2,
                  textTransform: "none",
                  background: "#2e64b8",
                  borderRadius: 3,
                }}
              >
                Achievements
              </Button> */}
            </div>
            <Grid2 container spacing={3} m={5}>
              {warmupData.length > 0 ? (
                warmupData.map((data) => (
                  <Grid2 size={{ md: 4, xl: 3, sm: 6, xs: 1 }}>
                    <Box borderRadius={5} sx={{ boxShadow: 4 }}>
                      <Box
                        sx={{ background: "#2e64b8" }}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        p={3}
                        borderRadius={5}
                      >
                        <Typography
                          color="white"
                          fontWeight={700}
                          fontSize={"1.25rem"}
                        >
                          {data.name}
                        </Typography>
                      </Box>
                      <Box>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          my={1}
                          px={3}
                        >
                          <Typography
                            fontWeight={500}
                            fontSize={"0.875rem"}
                            color="#999999"
                          >
                            No of questions
                          </Typography>
                          <Typography fontWeight={600}>
                            {data.questions.length}
                          </Typography>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"end"}
                          alignItems={"center"}
                          mb={1}
                          py={1}
                          px={1}
                        >
                          <Button
                            onClick={() => handleWarmpClick(data)}
                            endIcon={<PlayArrowIcon />}
                            size="small"
                            sx={{
                              borderRadius: 3,
                              textTransform: "none",
                              fontWeight: 600,
                              p: "6px",
                              background: "#2e64b8",
                              color: "white",
                              "&:hover": {
                                background: "#f3f8ff",
                                color: "black",
                              },
                            }}
                          >
                            Start
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Grid2>
                ))
              ) : (
                <Typography
                  fontWeight={700}
                  fontSize={"1.25rem"}
                  alignItems={"center"}
                  mt={20}
                >
                  No data to show
                </Typography>
              )}
            </Grid2>
          </>
        )}
      </Box>
    </>
  );
};
