import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
// import BackIcon from '../assets/images/back.png';
import { useNavigate } from "react-router-dom";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import GridViewIcon from '@mui/icons-material/GridView';

const Sidebar = ({
  categories,
  onItemClick,
  openCategory,
  setOpenCategory,
  selectedPPT,
  handleLogout,
  selectedPPTIndex,
  selectedSection,
  currentSection,
}) => {
  const navigate = useNavigate();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Toggle the sidebar open/close state
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Toggle which category is open/expanded
  const toggleCategory = (categoryId) => {
    setOpenCategory(openCategory === categoryId ? null : categoryId);
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  return (
    <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
      {/* <div className={`menu-icon ${isSidebarOpen ? 'right' : 'center'}`} onClick={toggleSidebar}>
        {'☰'}
      </div> */}
      {/* <div
        onClick={handleNavigate}
        style={{
          width: "50px",
          cursor: "pointer",
          marginLeft: "20px",
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          border: "1px solid black",
          borderRadius: "10px",
          padding: "0px 10px",
        }}
      >
        <ArrowBackIosIcon
          style={{ color: "#000", width: "15px", height: "20px" }}
        />
        <h5 style={{ margin: "5px", color: "#000" }}>Back</h5>
      </div> */}

      <div style={{ margin: '10px 10px 0px', display: 'flex', gap: '2px', alignItems: 'center', padding: '10px 50px', border: '2px solid rgb(46, 100, 184)', width: 'fit-content', borderRadius: '10px', cursor: 'pointer' }} 
        onClick={() => navigate("/dashboard")}
      >
        <GridViewIcon style={ { color: "rgb(46, 100, 184)" }}/>
        <h4 style={{ color: "rgb(46, 100, 184)", margin: 'auto' }}>Dashboard</h4>
      </div>

      {isSidebarOpen && (
        <div className="category-list">
          {categories.map((category, index) => (
            <div key={index} className="category">
              <div
                className="category-title"
                onClick={() => {
                  toggleCategory(index);
                  currentSection("PPT");
                }}
              >
                {openCategory === index ? (
                  <FaChevronDown className="expand-icon" />
                ) : (
                  <FaChevronRight className="expand-icon" />
                )}
                {category.title}
              </div>
              <div
                className={`items-list-wrapper ${
                  openCategory === index ? "expanded" : ""
                }`}
              >
                <ul className="items-list">
                  {category.items.map((item, itemIndex) => (
                    <li
                      key={itemIndex}
                      className="item"
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                      onClick={() => onItemClick(item, itemIndex)}
                    >
                      <strong
                        style={{
                          color:
                            selectedPPTIndex === itemIndex
                              ? "rgb(46, 100, 184)"
                              : "#969696",
                          fontSize: ".96rem",
                        }}
                      >
                        {item.title}
                      </strong>
                      {/* <p>{item.description}</p> */}
                      {selectedPPT?._id === item?._id && (
                        <SlideshowIcon
                          fontSize="small"
                          style={{
                            color:
                              selectedPPTIndex === itemIndex
                                ? "rgb(46, 100, 184)"
                                : "#000",
                          }}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="category-list" style={{ borderTop: "1px dashed" }}>
        <div
          className="category"
          onClick={() => {
            currentSection("generalWarmup");
            toggleCategory(null);
          }}
        >
          <div
            className="category-title"
            style={{
              color: selectedSection === "generalWarmup" ? "#1976d2" : "black",
              fontWeight: 700,
            }}
          >
            <FaChevronRight className="expand-icon" />
            Warm Up
          </div>
        </div>
      </div>
      <button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "50%",
          marginTop: "auto",
          alignSelf: "center",
          marginBottom: "10px",
          backgroundColor: "rgb(46, 100, 184)",
          border: "none",
          borderRadius: "10px",
          color: "white",
          padding: "10px",
          fontSize: "15px",
          cursor: "pointer",
        }}
        onClick={handleLogout}
      >
        <LogoutIcon style={{ color: "white" }} />
        Logout
      </button>
    </div>
  );
};

export default Sidebar;
