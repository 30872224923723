import React, { useEffect, useState } from "react";
import "../assets/styles/Login.css"; // Importing your CSS styles
import { useNavigate, useParams } from "react-router-dom";
// import { setUserDetails } from "../util/ApiClient";
import axios from "axios";
import { API_URL } from "../config";

export const Login = () => {
  const [username, setUsername] = useState(""); // State for username
  const [password, setPassword] = useState(""); // State for password
  const [error, setError] = useState(""); // State for error messages
  const [loading, setLoading] = useState(false); // State for loading indicator

  const navigate = useNavigate();

   useEffect(() => {
    const token = localStorage.getItem('token');
    if(token) {
      navigate('/dashboard');
    }
  }, []);

  const login = (userID, userPassword) => {
    return axios.post(`${API_URL}/api/Logon/login`, {
      userID,
      userPassword,
    });
  };

  // Form submission handler
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent page reload on form submit

    if (!username || !password) {
      setError("Please enter username and password");
      return;
    }

    setError(""); // Clear error if fields are valid
    setLoading(true); // Show loading indicator

    try {
      const { data } = await login(username,password );
      localStorage.setItem('token', data?.token);
      localStorage.setItem("userName", data?.userName);
      localStorage.setItem("role", data?.role);
      setLoading(false);
      setUsername("");
      setPassword("");
      setError("");
      navigate('/dashboard');
    } catch(e) {
      console.log(e);
      setLoading(false);
      setError("Invalid username or password");
      return;
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter userid"
              autoComplete="off" // Disabling browser autocomplete
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? "Logging in..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};
