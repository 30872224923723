import React, { useEffect, useState } from "react";
import AssessmentSection from "../components/AssessmentSection";
import { useParams } from "react-router-dom";
import { getAssessment, getWarmup } from "../services/AssessmentService";

function IndividualAssessment() {
  const { id, type, token } = useParams();
  const [module, setModule] = useState({});
  const [showAssessment, setShowAssessment] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!id) return;
        localStorage.setItem("token", token);
        let response;
        if (type === "warmup") {
          response = await getWarmup(id);
        } else {
          response = await getAssessment(id);
        }

        if (response) {
          console.log(response);
          if (type === "assessment") {
            setModule({
              ...response,
              title: response?.name,
              _id: response.module,
            });
          } else {
            setModule({
              ...response,
              title: response?.name,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [id]);

  return (
    <div>
      {module.name && (
        <AssessmentSection
          selectedModule={module}
          type={type}
          hideNavigation={'yes'}
          goBack={setShowAssessment}
        />
      )}
    </div>
  );
}

export default IndividualAssessment;
