import { useEffect } from "react";
import PPTImg from '../assets/images/ppt.png'

export const PPTTiles = ({ pptTiles, onItemClick, selectedItem }) => {
  

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      {/* <h2 style={{ textAlign: 'start', marginLeft: '20px', marginBottom: '20px', fontSize: '1.5rem', color: '#000' }}>{pptTiles?.categoryName}</h2> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "20px",
          marginLeft: "20px",
          marginTop: "20px",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {pptTiles?.items?.map((item, index) => {
          return (
            <div
              className="ppt-item"
              key={index}
              onClick={() => onItemClick(item, index)}
              style={{
                padding: "20px",
                borderRadius: "10px",
                minWidth: "180px",
                textAlign: "start",
                transition:
                  "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                cursor: "pointer",
                display: "flex",
                flexDirection: 'column',
                alignItems: "start",
                // maxHeight: "120px",
                minHeight: "120px",
                border: selectedItem === index ? "2px solid rgb(46, 100, 184)" : '1px dashed grey',
               
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              {/* <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS7-1u8oXFiDvghztNKyIbJgLs3Atk59pWzg&s"
                // style={{ height: "150px", width: "200px" }}
                style={{ height: "60px", width: "60px", borderRadius: '10px', maxWidth: '50%',  }}
                alt=" "
              /> */}
              <img
                src={PPTImg}
                // style={{ height: "150px", width: "200px" }}
                style={{  width: "100%", height: "auto", borderRadius: '10px',   }}
                alt=" "
              />
              <div>
                <h4
                  style={{
                    fontSize: "1rem",
                    color: "#2c3e50",
                    margin: "0px",
                    marginTop: "15px",
                  }}
                >
                  {item.title}
                </h4>
                <p
                  style={{
                    fontSize: ".8rem",
                    color: "#7f8c8d",
                    marginBottom: "10px",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,       // Limit to 2 lines
                    WebkitBoxOrient: "vertical", // Specifies the box orientation
                    overflow: "hidden",         // Hide overflow text
                    textOverflow: "ellipsis",
                    margin: "0px",
                    marginTop: '5px'
                  }}
                >
                  {item.description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
