import React, { createContext, useContext, useRef } from "react";
import { submitPPTTime, submitTime } from "../services/TimeTrackingService";

const ScreenTimeContext = createContext(null);

export const ScreenTimeProvider = ({ children }) => {
    
    const startTime = useRef(0);

    const pptStartTime = useRef(0);

    const startTracking = () => {
        startTime.current = Date.now();
    }

    const stopTracking = () => {
        const endTime = Date.now();

        const totalTime = (endTime - startTime.current) / 1000;

        submitScreenTime(totalTime);

    }

    const startPPTTracking = () => {
        pptStartTime.current = Date.now();
    }

    const stopPPTTracking = (title) => {
        const endTime = Date.now();

        const totalTime = (endTime - pptStartTime.current) / 1000;

        submitPPTScreenTime(totalTime, title);

    }

    const submitScreenTime = async(totalTime) => {
        try {
            await submitTime(totalTime);
        } catch(e) {
            console.log(e);
        }
    }

    const submitPPTScreenTime = async(totalTime, title) => {
        try {
            console.log("Time", totalTime);
            await submitPPTTime(totalTime, title);
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <ScreenTimeContext.Provider value={{ startTracking, stopTracking, startPPTTracking, stopPPTTracking }} >
            {children}
        </ScreenTimeContext.Provider>
    )
} 

export const useScreenTime = () => {
    const context = useContext(ScreenTimeContext);
    if(!context) {
        throw new Error("useScreenTime must be used within a ScreenTimeProvider")
    }
    return context;
}