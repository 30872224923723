import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { getUserModuleReport, getUserModuleVideoReport } from "../services/UserReportService";

export const UserModuleVideoAnalysis = () => {
    const colors = [
        "#2C3E50", // Dark Blue
        "#34495E", // Dark Slate Gray
        "#16A085", // Dark Teal
        "#1ABC9C", // Dark Turquoise
        "#8E44AD", // Dark Purple
        "#9B59B6", // Purple
        "#2980B9", // Dark Blue
        "#2ECC71", // Green
        "#27AE60", // Dark Green
        "#E74C3C", // Dark Red
        "#C0392B", // Red
        "#F39C12", // Dark Yellow
        "#F1C40F", // Yellow
        "#D35400", // Dark Orange
        "#E67E22", // Orange
        "#F7DC6F", // Light Yellow
    ];

  const navigate = useNavigate();

  const ranges = ["This Month", "This Week", "Today", "Custom"];

  const [selectedRange, setRange] = useState("This Month");
  const [startDate, setStartDate] = useState("Start");
  const [endDate, setEndDate] = useState("End");

  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState("All");


  const [chartState, setChartState] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        width: '80%',
        type: "bar", // Use exact string literal here
        events: {},
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "40%",
          distributed: true,
          borderRadius: 5
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        title: {
          text: "Module"
        },
        categories: [],
        labels: {
          show: true,
          style: {
            colors: colors,
            fontSize: "11px",
          },
        },
      },
      yaxis: {
        title: {
          text: "Time"
        }
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const value = series[seriesIndex][dataPointIndex];
          return `<div style="padding: 5px; color: #fff; background: #333; border-radius: 5px;">
                      ${value} Min
                    </div>`;
        },
      },
    },
  });

  useEffect(() => {
    if (selectedRange === "Custom") {
      const today = new Date();
      const yesterday = new Date(today);

      yesterday.setDate(today.getDate() - 1);

      setStartDate(yesterday.toISOString().split('T')[0]);
      setEndDate(today.toISOString().split('T')[0]);
    }
  }, [selectedRange]);

  useEffect(() => {
    (async () => {
      try {
          const { categories, series } = await getUserModuleVideoReport( selectedRange, startDate, endDate);
          setChartState((prev) => ({
            ...prev,
            series: [{ data: series }],
            options: {
              ...prev.options,
              xaxis: {
                ...prev.options.xaxis,
                categories: categories,
              },
           },
          }));
      } catch(e) {
        
            if(e.message === 'Token Expired') {
                console.log('logout>>>>>>>')
                localStorage.removeItem('token');
                navigate('/');
            }
        
      }
      
    })();
  }, [selectedModule, selectedRange, startDate, endDate]);

  return (
    <div
      className="graph-container"
      style={{
        textAlign: "start",
        background: "white",
        padding: "30px",
        width: "45%",
        borderRadius: "20px",
        margin: '0px 0px 0px 50px',
      }}
    >
      <h4>Module Wise Video Analysis</h4>
      
      <select
        id="classDropdown"
        style={{ marginLeft: "10px" }}
        onChange={(e) => setRange(e.target.value)}
      >
        {ranges.map((item, index) => {
          return <option value={item}>{item}</option>;
        })}
      </select>
      {selectedRange === "Custom" && (
        <>
          <input
            type="date"
            style={{ marginLeft: "10px" }}
            className="date-input"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <input
            type="date"
            style={{ marginLeft: "10px" }}
            className="date-input"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </>
      )}
      <div id="chart">
        <ReactApexChart
          options={chartState?.options}
          series={chartState.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};
 