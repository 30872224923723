import { Box } from "@mui/material";
import React from "react";

function Loader() {
  return (
    <Box
      height={"70vh"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <div className="loaderWrapper">
        <div className="loaderInner">
          <div className="loader">
            <span className="letter">P</span>
            <span className="letter">L</span>
            <span className="letter">E</span>
            <span className="letter">A</span>
            <span className="letter">S</span>
            <span className="letter">E</span>
            <div className="letterO"></div>
          </div>
          <div className="welcome">Please </div>
          <div className="welcome">Wait</div>
          <div className="welcome">We are evaluating</div>
          <div className="welcome">Your</div>
          <div className="welcome">Answers</div>
        </div>
      </div>
    </Box>
  );
}

export default Loader;
