import { useEffect, useState } from "react";
import { PPTTiles } from "./PPTTiles";
import axios from "axios";
import { API_URL } from "../config";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import PlayIcon from "../assets/images/play.png";
import { Button } from "@mui/material";
import { Skeleton } from "@mui/material";

export const PPTViewer = ({
  ppt,
  items,
  selectNext,
  selectPrevious,
  pptIndex,
  onItemClick,
  handleFullScreen,
  isFullScreen,
  setUpdated,
  setCategories,
  selectedCategoryIndex,
  selectedPPTIndex,
  selectedSection,
  setSelectedSection,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    console.log("PPT__", ppt);
    if (ppt.thumbnail) {
      setImageLoading(true);
    }
  }, [ppt]);

  useEffect(() => {
    if (imageLoading) {
      console.log("imageLoading", imageLoading);
    }
  }, [imageLoading]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine the width for the wrapper
  const wrapperWidth = windowWidth < 1400 ? "100%" : "calc(100% - 200px)";

  const updateCategoryItem = (categoryIndex, itemIndex, pptUrl) => {
    console.log("ppt-------------", pptUrl);
    setCategories((prevCategories) => {
      // Deep clone the previous categories array
      const updatedCategories = JSON.parse(JSON.stringify(prevCategories));

      updatedCategories[categoryIndex].items[itemIndex].ppt = pptUrl;

      return updatedCategories;
    });
  };

  const handleImageLoaded = () => {
    setImageLoading(false);
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "scroll",
        // background: 'linear-gradient(to top, #fae4f5, #f2effa)',
        background: "#FFFFFF",
        overflowX: "hidden",
        // height: "100vh",
        // overflow: "scroll",
        // position: "relative",
      }}
    >
      <div
        style={{
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          // backgroundColor: "white",
          // padding: "0 100px",
          // boxSizing: "border-box",
          width: "100%",
          height: "60vh",
          // position: "relative",
        }}
      >
        {/* Wrapper div for the iframe */}
        <div
          className="ppt-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h3
            style={{
              margin: "0px",
              marginTop: "10px",
              fontSize: "1.5rem",
            }}
          >
            {ppt.title}
          </h3>

          {/* <h5
          style={{
            margin: "0px",
            marginTop: '10px',
            fontSize: "1rem",
            fontWeight: "normal",
            color: 'rgb(127, 140, 141)',
            lineHeight: '22px',
            letterSpacing: '1px'
          }}
        >
          {ppt.description}
        </h5> */}
        </div>
        {
          ppt.ppt ? (
            <div
          className="iframe-wrapper"
          style={{
            // width: "calc(100% - 200px)",
            margin: "auto",
            marginTop: "10px",
            height: "100%",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)", // Box-shadow for the wrapper
            borderRadius: "10px", // Border radius for the wrapper
            overflow: "hidden", // Ensures the iframe respects the border-radius
            backgroundColor: "white",
            position: "relative",
          }}
        >
          <div
            style={{
              display: isLoading ? "none" : "block",
              position: "absolute",
              bottom: 2,
              left: 0,
              width: "300px",
              height: "22px",
              background: "#454343",
            }}
          ></div>
          {/* {isLoading && <p>Loading PPT...</p>} */}
          {/* <iframe
              id="ppt-element"
              title="PPT Viewer"
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}&embedded=true&wdHideDownload=1`}
              // src={`https://view.officeapps.live.com/op/embed.aspx?src=https://25d2-2409-40d0-203b-3a4e-c934-e2c1-a12f-56aa.ngrok-free.app/api/PPT/URL?key=${ppt.ppt}&embedded=true&wdHideDownload=1`}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                backgroundColor: "white",
                pointerEvents: isFullScreen ? "all" : "none",
                filter: "blur(2px)"
              }}
              frameBorder="0"
              onEncrypted={true}
              onLoad={handleIframeLoad}
            /> */}
          {/* {
            imageLoading ?
            <Skeleton variant="rectangular" width={1500} height={1500} /> : */}
          { ppt.thumbnail &&
            <img
            src={`${API_URL}/api/ppt/thumbnail/${ppt.thumbnail}`}
            alt=" "
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              backgroundColor: "white",
              filter: "blur(2px)",
            }}
            onLoad={handleImageLoaded}
          />
          }
          {/* } */}
          <img
            src={PlayIcon}
            alt=" "
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
              width: "100px",
              height: "100px",
              cursor: "pointer",
            }}
            onClick={handleFullScreen}
          />
          {/* <button
          style={{
            background: "rgb(46 100 184)",
            border: "none",
            cursor: "pointer",
            padding: "0px 20px 0px 20px",
            height: "40px",
            fontSize: "17px",
            color: "#FFFFFF",
            borderRadius: "5px",
            display: "flex",
            gap: "5px",
            alignItems: "center",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2,

          }}
          onClick={handleFullScreen}
        >
          Play
          <SlideshowIcon />
        </button> */}
          {/* <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "42%",
          height: "24px",
          background: "#FFFFFF",
        }}
      ></div> */}

          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "100%",
              height: "24px",
              background: "#FFFFFF",
            }}
          ></div>
        </div>
          ) : <div style={{ margin: '10px' }}>The presentation will be available soon...</div>
        }
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            // marginRight: "220px",
            marginTop: "auto",
          }}
        ></div>
        {
          ppt.ppt && (
            <PPTTiles
          pptTiles={items}
          onItemClick={onItemClick}
          selectedItem={selectedPPTIndex}
        />
          )
        }
      </div>
      {/* <PPTTiles
        pptTiles={items}
        onItemClick={onItemClick}
        selectedItem={selectedPPTIndex}
      /> */}
    </div>
  );
};
