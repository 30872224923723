import axios from "axios";
import { API_URL, getHeaders } from "../config";

export const submitTime = async (time) => {
  await axios.post(
    `${API_URL}/api/Logon/user/screen-time`,
    { time },
    { headers: getHeaders().headers }
  );
  return;
};

export const submitPPTTime = async(time, title) => {
  await axios.post(
    `${API_URL}/api/PPT/report/create`,
    { time, title },
    { headers: getHeaders().headers }
  )
  return;
}